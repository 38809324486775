import React from 'react';
import './HomeSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import BackgroundImage from "../../images/Fond1500x2000.png";
import { NavHashLink } from 'react-router-hash-link';
import Footer from '../../components/Footer/Footer';
import { height } from '@mui/system';




function HomeSection({
sectionId,
bannerImg,
mainText,
menu1Text,
menu2Text,
logoImg
}) {

const divStyle = {
  paperContainer: {
      backgroundImage :  'url('+ BackgroundImage+')',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition : 'center',
      position : 'relative',
  }
}; 

const ContainerNoPad = styled (Container)(({ theme })=>({
  '& .MuiContainer-root': {
    paddingLeft:'0px',
    paddingRight:'0px',
  }
}));

const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});





  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Container maxWidth="xl" >
           <Grid  container direction="column" justifyContent="center" alignItems="stretch" alignContent="center">         


                 <Grid item >     
                      <Box  display="flex" textAlign="center" className={'noselect'}  >
                                <img src={bannerImg} class={'home-imgtop'}   ></img>
                      </Box>   
                  </Grid>     
                  <Grid item > 
                      <Grid container   direction="row"   justifyContent="space-around"  alignItems="center" 
                            paddingTop='10px' paddingBottom='10px'  >
                              <Grid item >
                              <Box   textAlign="center" className={'home-menu noselect'} >
                                  <NavHashLink smooth to='/iwwonworld' className='home-links'  > 
                                    {menu1Text}    
                                    </NavHashLink>
                              </Box>

                              </Grid>

                              <Grid item >
                              <Box   textAlign="center" className={'home-menu noselect'} >
                                    <NavHashLink smooth to='/cryptomories' className='home-links'  >
                                    {menu2Text}    
                                    </NavHashLink>
                              </Box>

                              </Grid>
                      </Grid>
                      </Grid>
                      
                      
                      <Grid item >   
                      
                            <Box style={divStyle.paperContainer}    sx={{ minHeight:'100px'}}>



                                  <Box  display="flex" textAlign="center" className={'home-logobox noselect'} 
                                        justifyContent="center" 
                                        alignItems="center" 
                                        paddingLeft="15%" 
                                        paddingRight="15%" 
                                        paddingTop="20%"
                                        >
                                          <img src={logoImg}  className={'home-logoimg'}  ></img>  

                                  </Box>
                                  <Box  display="flex" textAlign="justify" className={'home-text'} 
                                        justifyContent="left" 
                                        alignItems="center" 
                                      
                                        paddingTop="20%"
                                        paddingBottom="20vh"
                                        >
                                          {mainText}           

                                  </Box>

                            </Box>
                      </Grid>
            </Grid>
            </Container>      

      </ThemeProvider>
    </>
  );
}

export default HomeSection;