import * as React from 'react';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue ,yellow} from '@mui/material/colors';
import{Box, Paper} from '@mui/material';
import BlockMain from './ClaimerCheckBlockMain';
import Block from './ClaimerCheckBlock';
import TextField from '@mui/material/TextField';

const ClaimerCheckerMain = (props)=> {
  
  const onClick = (rmNb) => {
    alert("onClick AllBlock ");
  };
  
  
  const [values, setValues] = useState({
    tokenId: '',
  });

  const handleChange = e =>  {
    setValues({  ...values,[e.target.name]: e.target.value,});
  };



  const displayBlocks=()=>{
    if (props.isExpanded) {
    return (
  

         <Block id={props.id} params={props.params} onClickProps={props.onClickProps} onCheckToken={props.onCheckToken}  status={props.status}   ></Block> 

  )
  }
  }
  
  return (
<>


<BlockMain  params={props.params}  onClickProps={props.onClickProps} id={props.id}   ></BlockMain>
{displayBlocks()}


</>
  );
}

export default ClaimerCheckerMain;