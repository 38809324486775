import { useEffect, useState } from "react";
import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { purple, green , yellow} from '@mui/material/colors';
import Button from '@mui/material/Button';
import ContractManager from '../../components/ContractManager/ContractManager';
import { contractManager } from './MyAdmin-data';
import './MyAdmin.css';
import Login from '../LoginPage/Login';
import detectEthereumProvider from '@metamask/detect-provider';

import MetaMaskStatus, {
  getCurrentWalletConnected,
  ReadBC,
  connectWallet
} from "../../utils/interact.js";
import { Typography } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import Twitter from '@mui/icons-material/Twitter';
import MetamaskStatus from "../../utils/interact.js";





const yellowPaper = yellow[500]; 
const colorBg = '#222'; 


const  MyAdmin  = props =>{
 
  
/* #region Stata Variable" */

  const [displayPerso, setDisplayPerso] = useState(false);

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [curChainId, setChainId] = useState("");

  const [token2Mint, setToken2Mint] = useState("");
  const [ nbOfOwnToken,setNbOfOwnedToken ] = useState("");

  const {contractAddress,abiFileName,CHAIN_ID} =  window['runConfig'];
  
  const contractABI = require('../../abi/' + abiFileName).abi;

  const [token, setToken] = useState(false);
  
/* #endregion */

  

useEffect(async () => {

  const provider = await detectEthereumProvider();
  
  if (provider) {
    
      var {CHAIN_ID} =  window['runConfig'];

      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
      if (_chainId != CHAIN_ID) { 
          setStatus(MetaMaskStatus.WRONG_NETWORK);
      } else {
          const walletResponse = await getCurrentWalletConnected();
          setStatus(walletResponse.status);
          setWallet(walletResponse.address);
      }
      addChainListener();
      addWalletListener();

    } else {
      setStatus(MetaMaskStatus.NO_METAMASK);
}

}, [status]);;


function addChainListener() {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId);
        if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
        }       
      });  
  }
}



function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setDisplayPerso(false);
      } else {
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      }
    });
  } 
}




const onButtonClicked = async () =>{
  if (window.ethereum) {
    const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
    setChainId(_chainId);
   if (_chainId != CHAIN_ID) {
        setStatus(MetaMaskStatus.WRONG_NETWORK);
   } else {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    if (status==MetaMaskStatus.ACCOUNT_CONNECTED) {
        const _owner = await ReadBC(contractABI,contractAddress,'owner',[]) 
      //alert("Owner = " + _owner + "\n selected adress "+ walletAddress );
     if  (walletAddress.toLowerCase() ==_owner.toLowerCase()) setDisplayPerso(true);
    }
   }
}else {
  setStatus(MetaMaskStatus.NO_METAMASK);
}
 
}//OnButtonClicked



  if(!token) {
    return <Login setToken={setToken} />
  }

  
  return (
    <>
     
     
     <ContractManager {...contractManager} />
      
     

    </>
  );
}

export default MyAdmin;