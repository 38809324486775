import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import MetaMaskStatus from "../../utils/interact.js";




const maintext =(status)=>{
  if (status==MetaMaskStatus.NO_METAMASK ) {
    return "You need an etherum wallet";
  };
  if (status==MetaMaskStatus.ACCOUNT_NOT_CONNECTED ) {
    return "Sign to Metamask";
  };
  if (status==MetaMaskStatus.WRONG_NETWORK ) {
    return "Wrong network . Please change network";
  };
return "Metamask connection problem."

}

const btnDisplay =(props)=>{
  if (props.status==MetaMaskStatus.NO_METAMASK ) {
    return   <Button sx={{marginRight:'auto',marginLeft :'auto' ,marginBottom:'25px' }}  
    href="https://metamask.io/download.html" rel="nofollow noopener" target="_blank"
    variant="contained" onClick={props.handleClose}>Get Metamask</Button>
  };
  if (props.status==MetaMaskStatus.ACCOUNT_NOT_CONNECTED ) {
    return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'25px'  }}  
      variant="contained" onClick={props.signIn}>Sign in</Button>
  };
  if (props.status==MetaMaskStatus.WRONG_NETWORK ) {
    return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'25px' }}  
      variant="contained" onClick={props.handleClose}>OK</Button>
  };
  return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'25px' }}  
  variant="contained" onClick={props.handleClose}>OK</Button>
}




export default function AlertNoMetaMask(props) {
  return (
    <Dialog 
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ overflow:'hidden'}}
        disableScrollLock={true}
      >
  <DialogTitle id="alert-dialog-title"   >
        <Box width="250px" textAlign='center'  >{maintext(props.status)}</Box>
  </DialogTitle>
  <DialogContent sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'50px' ,marginTop:'20px' }} >
            <img  width='150px' height='150px'  src='images/deco/metamask.png'  />
</DialogContent>
<DialogActions>
    {btnDisplay(props)}
</DialogActions>
</Dialog>



  );
}

