import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Login.css';


const backendURL="https://spikyspacefish-backend.azurewebsites.net";
//const backendURL="http://localhost:3001";

async function loginUser(credentials) {
    return fetch(backendURL+'/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }






export default function Login({ setToken }) {
  
  
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();


  const handleSubmit = async e => {
    
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    console.log("Token:" + token);
    setToken(token);
  }


  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="text" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};