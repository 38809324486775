import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
      fontFamily: 'Eras Bold',
     },
     breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1500,
        xxl: 3000,
      },
    },
   })

export default theme;