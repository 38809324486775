import React, { useState, useEffect } from "react"
import { FaTrash } from "react-icons/fa"
//import styles from "./TodoItem.module.css"
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {purple} from '@mui/material/colors';
import TextField from '@mui/material/TextField';



const ContractItem = props => {
  
  
    const [inputText, setInputText] = useState({
        title: '',
      });
      //const [value, setValue] = useState('...');
      
      const [editing, setEditing] = useState(false)

     
      const handleEditing = () => {
          
        if (props.item.itemType==3)  setEditing(true)
      }
    
     
      const onChange = e => {
         console.log("input =" + inputText.title) ;
       console.log([e.target.name]) ;
        setInputText({
          ...inputText,
          [e.target.name]: e.target.value,
        })

      }
    
      
      const writeOnBC = async (e) => {
       if (props.item.itemType==3) {
           const retVal = await props.writeOnBC(props.item.writeFn,props.item.id,[inputText.title]);
           setEditing(false)
       } else {
        const retVal = await props.writeOnBC(props.item.writeFn,props.item.id,[]);
       }
    }

      const readFromBC = async (e) => {
        setEditing(false)
       // alert("read " + props.item.readFn )
        const retVal = await props.readFromBC(props.item.readFn,props.item.id,[]);
        setInputText(retVal);
  }



  useEffect(() => {
    return () => {
      console.log("Cleaning up...")
    }
  }, [])


  return (
    <Paper
    onDoubleClick={handleEditing} 
    component="form" 
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' ,  
                  marginLeft :'auto', 
                  marginRight:'auto',
                  marginBottom:'10px',p:'10px'}}  >

            { (editing) 
            ?<TextField  
                            InputLabelProps={{ shrink: true,}}
                            sx={{width:'550px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                            label={props.item.varName + " " + props.key}  
                            value={inputText.title}
                            name="title"
                            onChange={onChange}
                            />  
           : <TextField  
                        InputLabelProps={{ shrink: true,readOnly: true,}}
                        sx={{width:'550px',marginRight:'15px' }} id="outlined-basic" variant={(props.item.itemType)==3? "outlined" :"filled"}
                        label={props.item.varName + " " + props.item.id}  
                        value={props.varValue}
                        name="title"
            />
            }
                           

            <Button  sx={{width:'100px',marginRight:'15px' }}    
                    variant="contained"  
                    onClick={readFromBC} > READ</Button>
          
          
           { (props.item.itemType != 1)   
              ? <Button sx={{width:'100px'}}variant="contained" 
              onClick={writeOnBC} >Write</Button>
              : <Button sx={{width:'100px'}}variant="contained" disabled >WRITE</Button>
           }
     </Paper>
  )
}

export default ContractItem