import * as React from 'react';
import Button from '@mui/material/Button';
import MetamaskStatus from '../../utils/interact.js';
import { styled } from '@mui/material/styles';
import { green,yellow,orange } from '@mui/material/colors';

const greenBtn = orange['300']; 


const MintButton = styled(Button)(({ theme }) => ({
   variant:'contained',
   elevation : 10, 


   [theme.breakpoints.up("sm")]: {
      width:'200px',
      height:'80px',
      fontSize:20,
      borderRadius:'5px',
    },
   
    [theme.breakpoints.down("sm")]: {
      width:'100px',
      height:'50px',
      fontSize:15,
      borderRadius:'5px',
    },
   
   color:'#072A48',
   backgroundColor: greenBtn,
   '&:hover': {
     backgroundColor: 'tomato',
   },
   fontFamily:'Eras Bold',
 }));



const btnLabel = (props) => {



   switch (props.status) {
         case MetamaskStatus.ACCOUNT_CONNECTED :
            return "Claim " + props.tokenToClaim;
        
         case MetamaskStatus.ACCOUNT_NOT_CONNECTED :
         return "Connect to Metamask";

         case MetamaskStatus.WRONG_NETWORK :
         return "Wrong Network";

         case MetamaskStatus.NO_METAMASK:
         return "Install Metamask";
   }


}







export default function SaleButton(props) {
  return (
   
   <>
   
   
      <MintButton  sx={{ boxShadow: 6 }}  onClick={props.onClick}  disabled={props.phaseStatus =='beforesale'} > 
   
                  {btnLabel(props)}
           </MintButton>
   
  </>
   );
}

