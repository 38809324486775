
require('dotenv').config();


var Web3 = require('web3');


const MetamaskStatus = {
  NO_METAMASK: "No metamask",
  ACCOUNT_CONNECTED: 'Account connected',
  ACCOUNT_OWNER_CONNECTED: 'Account owner connected',
  ACCOUNT_NOT_CONNECTED: 'Account not connected',
  WRONG_NETWORK:'Wrong Network'
}
export default MetamaskStatus




export const connectWallet = async () => {
    if (window.ethereum) {
     // if (!window.web3)   window.web3= new Web3(window.etherum)
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: MetamaskStatus.ACCOUNT_CONNECTED,
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: MetamaskStatus.ACCOUNT_NOT_CONNECTED,
          err: err.message
        };
      }
    } else {
      return {
        address: "",
        status: MetamaskStatus.NO_METAMASK
      };
    }
  };

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
     //if (!window)   window.web3= new Web3(window.etherum)
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: MetamaskStatus.ACCOUNT_CONNECTED,
            err:0,
          };
        } else {
          return {
            address: "",
            status:  MetamaskStatus.ACCOUNT_NOT_CONNECTED,
            err:1,
          };
        }
      } catch (err) {
        return {
          address: "",
          status: MetamaskStatus.ACCOUNT_NOT_CONNECTED,
          err: err.message
        };
      }
    } else {
      return {
        address: "",
        status: MetamaskStatus.NO_METAMASK,
        err:2, 
      };
    }
};


export const getBalanceOf = async(contractABI,contractAddress ) => {
  var web3 = new Web3(window.ethereum);
  const retVal= await web3.eth.getBalance(contractAddress);
  return  Web3.utils.fromWei(retVal);
}



export const  ReadBC = async(contractABI,contractAddress,fn,args) => {
  //var web3Provider = window.web3.currentProvider;
  var web3 = new Web3(window.ethereum);
 //alert("interact-ReadBC fn :" + fn + " args : " + args);
  const myContract  = await new web3.eth.Contract(contractABI, contractAddress);

  const retVal = await myContract.methods[fn].apply(this,args).call();
//alert( "interact-ReadBC after " + retVal);
      return retVal;

};



export const WriteBC = async(contractABI,contractAddress,fn,dummy,args) => {
  //alert("interact-WriteBC fn= " + fn + " nboftoken " + nbOfToken) ;
  var web3Provider = window.web3.currentProvider;
  var web3 = new Web3(window.ethereum);
  const myContract = await new web3.eth.Contract(contractABI, contractAddress);
  const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
  var tokenPrice=0;
 
  //the transaction
      const tx = {
        'from': window.ethereum.selectedAddress,
        'to': contractAddress,
        'nonce': nonce.toString(),
        'value':  0,
        'data': myContract.methods[fn].apply(this,args).encodeABI()
      };

      //'gas': (2000000).toString(),
//sign the transaction via Metamask
try {
  console.log("value "+ tx.value);
  const txHash = await window.ethereum
    .request({
        method: 'eth_sendTransaction',
        params: [tx],
    });
  return {
    success: true,
    status: "Transaction hash :" + txHash
  }
  } catch (error) {
  return {
    success: false,
    status: "😥 Something went wrong: " + error.message
  }
  
  }
}

export const getMetaFile = async(contractABI,contractAddress,tokenId)=>{
  const tokenURI=await ReadBC(contractABI,contractAddress,"tokenURI",[tokenId]);
  return tokenURI;
}



  export const getNbOfOwnedToken = async(contractABI,contractAddress) => {
   
    if ((window.ethereum) && (window.ethereum.selectedAddress)) {
      //var web3Provider = window.web3.currentProvider;
      var web3 = new Web3(window.ethereum);      
        const myContract = await new web3.eth.Contract(contractABI, contractAddress);

       const nbOfOwnToken = await myContract.methods.balanceOf(window.ethereum.selectedAddress).call();
            console.log("Nb Of Owned Token :" + nbOfOwnToken)
        return {
         ownedToken : nbOfOwnToken
        };
      } else {
        return {
          ownedToken : 0
         };
      }
  };

  export  const getTokensFromAddr= async (contractABI,contractAddress,oneAddr)=>{
    var tokenId;
    var tokens=[];
    var i=0;

    var web3 = new Web3(window.ethereum);
    const myContract = await new web3.eth.Contract(contractABI, contractAddress);  
    const nbOfToken = await myContract.methods.balanceOf(oneAddr).call();  
    //alert("nbOfToken  :" + nbOfToken);
  
    for(i=0;i<nbOfToken;i++){
      
       tokenId = await myContract.methods.tokenOfOwnerByIndex(oneAddr,i).call();  
      console.log("TokenId  :" + tokenId );
      
      tokens.push(tokenId);
  
    }
    tokens.sort(function(a, b){return a - b});
  
  
    return tokens;
  
  }


export const mint = async(contractABI,contractAddress,nbOfToken) => {
  //var web3Provider = window.web3.currentProvider;
  var web3 = new Web3(window.ethereum);
  const myContract = await new web3.eth.Contract(contractABI, contractAddress);
  const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
  const tokenPrice = await myContract.methods.tokenPrice().call();
  console.log("Token Price :" + tokenPrice) ;
  //the transaction
      const tx = {
        'from': window.ethereum.selectedAddress,
        'to': contractAddress,
        'nonce': nonce.toString(),
        'value': (nbOfToken * tokenPrice).toString(16),
        //'value': (web3.utils.toWei('0.08', 'ether')*nbOfToken).toString(16),
        'data': myContract.methods.mintToken(nbOfToken).encodeABI()
      };

      //'gas': (2000000).toString(),
//sign the transaction via Metamask
try {
  console.log("value "+ tx.value);
  const txHash = await window.ethereum
    .request({
        method: 'eth_sendTransaction',
        params: [tx],
    });
  
    return {
    success: true,
    status:"Transaction hash :" + txHash
  }
  } catch (error) {
  return {
    success: false,
    status: "😥 Something went wrong." + error.message
  }
  
  }


}

export const claimToken = async(contractABI,contractAddress,nbOfToken) => {
  //var web3Provider = window.web3.currentProvider;
  var web3 = new Web3(window.ethereum);
  const myContract = await new web3.eth.Contract(contractABI, contractAddress);
  const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
  const tokenPrice = await myContract.methods.tokenPrice().call();
  console.log("Token Price :" + tokenPrice) ;
  //the transaction
      const tx = {
        'from': window.ethereum.selectedAddress,
        'to': contractAddress,
        'nonce': nonce.toString(),
        'value': (0).toString(16),
        //'value': (web3.utils.toWei('0.08', 'ether')*nbOfToken).toString(16),
        'data': myContract.methods.claimToken(nbOfToken).encodeABI()
      };

      //'gas': (2000000).toString(),
//sign the transaction via Metamask
try {
  console.log("value "+ tx.value);
  const txHash = await window.ethereum
    .request({
        method: 'eth_sendTransaction',
        params: [tx],
    });
  
    return {
    success: true,
    status:"Transaction hash :" + txHash
  }
  } catch (error) {
  return {
    success: false,
    status: "😥 Something went wrong." + error.message
  }
  
  }


}






