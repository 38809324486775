import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './Footer.css';
import {openInNewTab} from '../../utils/myLib';
import Twitter from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';

export default function Footer({
    colorBg,
  }) {
  

    const onTwitterClick = () => {
        const url="https://twitter.com/iwwon_official";
        openInNewTab(url);
      } 
      const onDiscordClick = () => {
        const url="https://discord.com/channels/833666166915596309/833666167381688351"
        openInNewTab(url);
      } 



   return (

        
        <Box  display="flex"  justifyContent="center" alignItems="center" className={'footer noselect'}  >
            <Grid container  justifyContent="center" alignItems="center" alignContent="center"   spacing={{xs:1 , sm:4}}
            >
                <Grid item >
                        <IconButton onClick={a=>{onTwitterClick()}}>
                        <Twitter sx={{fontSize:'60px',color:'#fff'}} />
                        </IconButton>
                </Grid>
                <Grid item  >
                <Box  display="flex"  justifyContent="center" alignItems="center" className={'noselect'} >
                      <IconButton onClick={a=>{onDiscordClick()}}>
                      <img src='images/deco/discord.png'  className={'footer-discord'}  ></img>
                      </IconButton>
                      </Box>
                </Grid>
                </Grid>
        </Box> 

    )
}
