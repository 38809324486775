
const roadmaps = [
    {id:0,
        title: 'Handmade',
        image : '/images/roadmap/lossamos.jpg',
        corpse :<div>I am corpse</div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div>
                        <p>Except for los samos, all hand made series are free and can only be won through combinations 
                            of iwwon tokens from different series.</p>
                    </div>       
        },
        {   id:1,
            title: <p>Los Samos</p>,
            image : '/images/roadmap/lossamos.jpg',
            corpse :<div>
                    <p>Los Samos are revolutionaries. They fight for freedom of speech, of art, of finance, of economy. 
                        They are colorful spirits that blur the line between avatars and stand-alone art. 
                        They are named after SAMO, that Jean-Michel Basquiat used to write on walls before he started painting. 
                        Same Old Shit. They are the first series of the iwwon project.<br/><br/>These artworks 
                        are printable in 200cm x 200cm.<br /><br/></p>
                    <p>Los Samos are the OGs. They have the most advantages. <br /><br />
                    Samos get a free mint of each new generative series.<br /><br />
                    Gifts are given to Samos randomly. <br /><br />
                    Early December, 25 Mories will be randomly given to Los Samos owners.<br /><br /></p>
                    <p>Raffles for upcoming drops are accessible only for Samos, Samos raffle tickets owners, 
                        and Basquiat crowned Mories. They will be at the initial price of 0.08. Some of them will be auctioned for all.
                        </p>
                    </div>       
        },  {   id:2,
            title: <p>Vandals</p>,
            image : '/images/roadmap/vandals.png',
            corpse :<div>
                    <p>Vandals are raw, electric and charismatic graffiti portrait that can be used as PFP.<br/><br/>They are raffled and free. 
                        To participate in the raffle, one must own a samos and a morie. Just art and vibes. 
                        Main utility is owning a handmade artwork from iwwon and a unique pfp. Owning a Vandals and a Samos Nebulae 
                        will give you access to the iwwon glyphs raffles.</p>
                    </div>       
        }, {   id:3,
            title: <p>Samos Nebulae</p>,
            image : '/images/roadmap/SamosNebulae.png',
            corpse :<div>
                            <p>Samos nebulae is a series of work iwwon has been working on for the last few years. 
                                It is the closest to his physical paintings. 
                                By playing with the boundaries between abstract and figurative, the series is an attempt to represent the soul.<br/><br/>
                                Those works can only be won by becoming one 
                                of the 3 Mories of the month.<br /><br /> Owning this series as well as a Vandals will make you eligible 
                                to the raffles of the iwwon glyphs series. These artworks are printable in 200cm x 200cm.</p>
                    </div>       
        }, {   id:4,
            title: <p>iwwon glyphs</p>,
            image : '/images/roadmap/iwwonGlyphs.png',
            corpse :<div>
                        <p>The iwwon glyphs series is a handmade series of iwwon signature patterns. 
                            Those patterns can be found in los Samos, on mories tattoos, in the Samos Nebulae series.<br /><br /></p>
                        <p>They are raffled and free. To participate in the raffle, one must own a Vandals and a Samos Nebulae.</p>
                        <p>Owning an iwwon glyphs as well as the Christmas gift will give you access to something super-secret.</p>
                    </div>       
        },
        ],
    } , 
    {id:1,
        title: 'Cryptomories',
        image :  '/images/roadmap/mories.jpg',
        corpse :<div>I am cryptomories</div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div><p>From the Latin saying “memento mori”, which means remember you die, the Mories are little skeletons here 
                            to remind you to live your life to the fullest. They have big personalities that can match with their owners, 
                            all the while making great profile pictures. The community is all about kindness, supporting one another, and positivity. 
                            We all grow together. Mories have grown organically and built solid foundations 
                            by leaning on one another and become not just friends,but a FaMorie.</p>
                    </div>
        },
        {   id:1,
            title: <p>Liquidity pool</p>,
            image : '',
            corpse :<div><p>A liquidity pool will allow Morie owners to easily access the equity in their Morie. 
                            It allows for an exit without entertaining low offer “bot” bids which in turn can affect the floor value. 
                            It also allows anytime access to floor value in times of immediate need. The price paid overtime adjusts 
                            with the vault dynamics and will remain near floor value. 
                            The liquidity pool has been in process in the background. We’re still working on this, 
                            and expect the pool to be launched by the end of the year.</p></div>
        },
        {   id:2,
            title: <p>IP rights</p>,
            image : '',
            corpse :<div>This is our top priority right now and we have been in talks with legal professionals 
                        to make sure that we are going about it the proper way. 
                        We have maintained the belief that we love and want to celebrate the artistic nature and 
                        creativity of our FaMorie and don’t want to put any sort of restrictions on that.
                     </div>
        },
        {   id:3,
            title: <p>Website</p>,
            image : '',
            corpse :<div>A new and improved website is being built 
                where the FaMorie will have access to high-res files of their Mories. 
                We are also building out our own custom rarity tool system which will be included on our website.
            </div>,
        },
        {   id:4,
            title: <p>Merch</p>,
            image : '',
            corpse :<div>We are collaborating with various retailers in order to ensure we can bring some high quality gear. 
                        Our goal is to have a small subset of merch available for the holidays, 
                        and we aim to launch our full line, and custom merch in the New Year. 
                        And yes, you can expect to see the same merch (POM/CM, etc) your Mories wear, 
                        available to us humans, outside the metaverse.
                </div>
        },
        {   id:5,
            title: <p>Contests rewards</p>,
            image : '',
            corpse :<div> Shoes from the Morie Story contest and Halloween mories from the Costume contest are dropped. 
            Setting up the Morie philosophy and planning the future for the mories has taken us some time. 
            Future rewards will be dropped immediately after announcement.
                    </div>
        },{   id:6,
            title: <p>Mission Statement</p>,
            image : '',
            corpse :<div> Mental Health has been our biggest mission and will continue to be for the entirety of this project. 
                           The FaMorie has really embraced it in ways that we never could have imagined and we plan to be the project 
                           for mental health advocacy in the NFT space. We have already started the funding for free therapy sessions 
                           for the FaMorie through our Thanksgiving Auctions on 25 Mories. The idea has been to not only destigmatize mental health, 
                           but to connect our community with the resources they need to get support with whatever it is that they’re going through. 
                           Our Mories Help Mories channel has been one facet of this and we are planning to center Spaces around this very topic 
                           where people can be vulnerable and not only seen, but heard as well.
                    </div>
        },{   id:7,
            title: <p>Decentralized Community</p>,
            image : '',
            corpse :<div>Your Morie provides you unique access to our future real community events and meetups. 
                        Whether it be a small gathering, a concert, or a live talk, your Morie acts as a key card 
                        and a reward system. At these events, there may also be an opportunity to have yourself a pizza 
                        at the B&B Pizza Truck! The B&B pizza truck will be an example of how you can spend 
                        your $MORI token. More on that below.
                        We have high hopes to build our own, “MorieCity”, one day, somewhere far off in the metaverse.
                    </div>
        },{   id:8,
            title: <p>Game, 3d mories and $tokenomics</p>,
            image : '',
            corpse :<div>We want to create long term value for our FaMorie. Mories are great for a PFP, but they can also 
                         do a lot of work! Our goal is to create some wholesome fun with the opportunity to earn $MORI tokens as prizes. 
                         We are creating a p2e game where Mories will be able to use their 3D Morie to battle other Mories with love bombs, 
                         positivity lasers, and other fun weapons to earn $MORI. We are also looking into how we could use $MORI to 
                         officially name your Mories. 3D Mories will also be useful in other metaverses. We remind you that we are a gaming studio 
                         and that this has been what differentiates us. 
                         Few in the space know what it really takes to deliver a high-end game and we know 
                         that this will be what sets us apart. 
                    </div>
        },
        {   id:9,
            title: <p>Mories and Friends</p>,
            image : '',
            corpse :<div> The FaMorie is growing! Some very “Cool Apes” have been spotted hanging around lately. 
                          It looks like they may stick around as they will be free for all FaMorie members. 
                          The Cool Apes will also be sold in a public sale to ensure we have enough people to 
                          operate our future game and tournaments. Each Cool Ape will have a 3D version 
                          that is usable for future gaming, along with the $MORI token.
                    </div>
        },

        ],
    } ,
    {id:3,
        title: <p>iwwon AirLines</p>,
        image :  '/images/roadmap/iwwonAirlines.png',
        corpse :<div></div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div>Iwwon airlines are iwwon collections of limited gifts and air drop.
            There are two collections
            Iwwon airlines 721. That are for 1/1 tokens on ERC-721 tokens.
            iwwon airlines 1155 : for multiple editions token on ERC-1155 contract.
            </div>
        },
        {   id:1,
            title: <p>Samos raffle tickets</p>,
            image : '',
            corpse :<div>The Samos Raffle Tickets are the new early believer cards. Early Believer Card was created to allow early 
                believers that didn't manage to mint the Samos (they were sold out in seconds) to still have some advantages in the iwwon project. 
                But I had trouble deciding what advantages each series would have. Samos having been affordable for a long time before the mories, 
                I decided that they had to be the one with the advantages. 
                The early believer card would only keep its initial utility which was to have the only access to the Samos drops. 
                Hence the name change.</div>
        },
        {   id:2,
            title: <p>Halloween mories</p>,
            image : '',
            corpse :<div>Winners of the costume contest will find their Halloween mories 
                        here once the fundamental tasks are done for the mories. Expect December.</div>
        },
        {   id:3,
            title: <p>Collaboration with artist</p>,
            image : '',
            corpse :<div>Morie derivatives from well-known artists are coming. </div>
        },
        {   id:4,
            title: <p>Genesis</p>,
            image : 'none',
            corpse :<div>Two of the original sketches for the Mories and two of the original one for the Samos will be auction.</div>
        },
        {   id:5,
            title: <p>Custom shoes</p>,
            image : '',
            corpse :<div>Mories that have written the most amazing Morie stories have won custom shoes signed by iwwon. 
            Those shoes will be made in December. 
            </div>
        },
        {   id:6,
            title: <p>Grandma Morie</p>,
            image : '',
            corpse :<div>Remember the 0.02 scandal grandma did? Well, Grandmaorie is coming to the iwwon airlines auction.</div>
        },
        {   id:7,
            title: <p>Trip to Mexico</p>,
            image : '',
            corpse :<div>Patterns on the Mories are inspired by the Día de los Muertos in Mexico where people celebrate 
                the dead and paint skulls with patterns 
                on their faces. We will be holding a contest to win a trip to paradise for 2.</div>
        },
        {   id:8,
            title: <p>Surfboard</p>,
            image : '',
            corpse :<div>How can Mories survive a Tsunamorie without a surfboard? 
                        Contest for a custom surfboard is on the map.
                    </div>
        },
        {   id:9,
            title: <p>More...</p>,
            image : '',
            corpse :<div>There is always more. But surprises are cool too</div>
        }
        ],
    } ,
    {id:4,
        title: <p>Christmas</p>,
        image :  '/images/roadmap/Christmas.png',
        corpse :<div>I am cryptomories</div>,
        blocks : [
        {    id:0,
            title: <p>Christmas present</p>,
            image : '',
            corpse :<div>Each Samos, Mories, will receive 1 gift. <br/>
            If you own 4 mories, you get 4.<br/> If you own 20 Mories and 4 Samos, you get 24...</div>
        },
         ],
    } ,    
    {id:5,
        title: <p>TP</p>,
        image :  '/images/roadmap/TP.png',
        corpse :<div></div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div>TP is an explosive project for amigos only.<br/>
            The roadmap is vibes only.<br/>
            No team.<br/>
            Full Rogue mode.<br/>
            Community owned discord.<br/>
            Possibly end of January.<br/>
            Must own a Morie or a Samos to mint.
            Samos get one free.
            </div>
        },
        {   id:1,
            title: <p>Apes giveaway</p>,
            image : '',
            corpse :<div>300 ETH worth of Apes or Mutant apes (to be decided by the community) will be randomly 
                given away to the TP owners after sell out. 
                    300 ETH goes straight into the Morie project.
            
            </div>
        },       {   id:1,
            title: <p>Bad Apes</p>,
            image : '',
            corpse :<div>10 000 Bad Apes by iwwon airdropped. 
                Bad apes are an artistic series with no roadmap, advantages, or utility. They can be used as pfp.
            </div>
        },
        ],
    },
    {id:6,
        title: <p>POM</p>,
        image :  '/images/roadmap/POM.png',
        corpse :<div>I am cryptomories</div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div>80% experience, 200% artistic, 1000% fun.<br/>
            Artistic generative series of 1000 team of pilots.<br/>
            Artistic generative series of spaceships. Number unknown.<br/>
            Generative series of pilots. But there is a prophecy that will change the game…. <br/><br/>
            The POM series will share the $MORI / $SAMO token.<br/>
            There is no drop date.<br/>
            There is no price yet.<br/>
            Must own a Morie or a Samos to mint.<br/>
            1 free mint for Samos Owners
            </div>
        },
        ],
    },
    {id:7,
        title: <p>Collabs</p>,
        image :  '/images/roadmap/SSF.png',
        corpse :<div>I am cryptomories</div>,
        blocks : [
        {   id:0,
            title: <p>Spiky Space Fish</p>,
            image : '/images/roadmap/SSF.png',
            corpse :<div>Spiky Space Fish are a collaboration between the iwwon team and the SSF team.
            300 whitelist spots will be given away to the Morie community.<br/>
            The fishes are beautiful artworks that serves as entry tickets to the world of Lejopan. <br/>
            Lejopan has a complex political situation. And those who are lucky enough to own the fish 
            will have an immense power over the future of Lejopan.<br/><br/>
            The iwwon team worked on the concept, the website and as advisers. 
            Price: 0.06<br/><br/>
            <div><a href="https://www.spikyspacefish.com" target="_blank">www.spikyspacefish.com</a></div>
            </div>
        },
        {   id:1,
            title: <p>One love</p>,
            image : '/images/roadmap/Onelove.png',
            corpse :<div>This project is a collaboration with a soul similar to the Mories. <br/>
                    It fights for unity, for rights, for love and for diversity.
                    It is a extremely joyful and fun project. <br/>The iwwon team helps on the contract, 
                    marketing, and as advisers.
                    Coming mid January for 0.02-0.03.
                    </div>
        },
        {   id:2,
            title: <p>The Origins</p>,
            image : '/images/roadmap/Origins.png',
            corpse :<div>The Origins are artworks made by iwwon and artist Zelroug. <br/>
                They have been exhibited in NY, London and Paris. It is bloody time they come to the metaverse.<br/><br/>
                Inspired by neuronal structure, by ancient civilizations, mythology and cosmos, these giants screens are an attempt to capture this energy that connects us all.
                <br/>
                The constant flow of highly detailed, colorful and symetrical patterns creates at each instant hundreds of faces, 
                creatures and gods that the viewer magically sees appearing and morphing into each other, 
                providing a unique, hypnotical and mesmerising experience.
             <br/><br/>

            The videos and 1500 images taken from the videos will lsited as NFTs. Images are super high res and 
            can be printed masterpiece size.
            </div>
        },
        ],
    }

]




export const roadmap = {
          sectionId:'Home',
          title:<p>World of iwwon</p>,
          backgroundImg:'images/deco/Fond1500x800.png',
          firstExpanded:false,
           roadmaps : roadmaps,       
          };

          export const mories = {
            sectionId:'Mories',
            title:<p>CryptoMories</p>,
            backgroundImg:'images/deco/Fond1500x800.png',
            firstExpanded:true,
             roadmaps : [{id:1,
                    title: 'RoadMap',
                    image :  '/images/roadmap/mories.jpg',
                    corpse :<div>I am cryptomories</div>,
                    blocks : roadmaps[1].blocks,       
            }]
        };      

