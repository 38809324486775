import * as React from 'react';
import './CCComp.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';



import MetaMaskStatus from "../../utils/interact.js";
import { blueGrey } from '@mui/material/colors';






const btnDisplay =(props)=>{
  return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'15px' }}  
  variant="contained" onClick={props.handleClose}>OK</Button>
}






export default function AlertContract(props) {
  return (
    <Dialog 
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ overflow:'hidden'}}
        PaperProps={{
          style: {
           // backgroundColor: '#fbf0c3', 
            maskBorderWidth: '0px'
          },
        }}
        disableScrollLock={true}
      >
   <DialogContent sx={{marginBottom:'0px' ,pr:'10px',pl:'10px',pt:'10px',
        marginTop:'0px',  minWidth:'200px',
        maxWidth:'300px',minHeight:'100px' }} >
        <Grid container  alignItems="center" display="flex" alignContent="center" justifyContent="center" direction="column"  >
     {(props.contentImg) &&
    <Grid item >
        <Box >
          <img width='190px' height='190px' src={props.contentImg} class={'cccomp-imgpopup'}  />
        </Box>
      </Grid> 
      }
        {  (props.waitingForImg) &&
      <Grid item >
        <Box >
            <CircularProgress/>
        </Box>
      </Grid> 
        }
      <Grid item>
        <Box   textAlign='center'  sx={{fontSize:'16px'}} >{props.corpse}</Box>
      </Grid>  

   </Grid>    

</DialogContent>
<DialogActions>
    {btnDisplay(props)}
</DialogActions>
</Dialog>



  );
}

