import * as React from 'react';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';
import './RMComp.css';

export default function titi(props) {

  const PaperColor = styled(Paper)({
    backgroundColor:'#6aceeb10'

  });

  const onClick = (rmNb) => {
      alert("onClick BlockMain ");
    };


return (
<>




<PaperColor  sx={{marginBottom:"1%"}} elevation={4}  onClick={() => props.onClickProps(props.id)} className={'noselect'}   >
<Grid container direction="column"
  justifyContent="center"
  alignItems="center">

{ (props.roadmap.title !='') &&
    <Grid item>
        <Box  display="flex"  className={'rmcomp-blocktitle'} justifyContent="center" alignItems="center" marginTop="15px" >
                          
                          {props.roadmap.title}  

        </Box>
    </Grid>
}
    { (props.roadmap.image !='') &&
    <Grid item>
        <Box  display="flex"   justifyContent="center" alignItems="center" marginTop="15px" marginBottom="5%" >
                          
                          <img src={props.roadmap.image}class={'rmcomp-imgblock'} ></img>

        </Box>
    </Grid>
}
    <Grid item>
        <Box  display="flex"  className={'rmcomp-text'} justifyContent="center" alignItems="center" textAlign="justify"
            marginTop="15px" marginBottom="20px" >
                          {props.roadmap.corpse}  
        </Box>
    </Grid>

</Grid>
</PaperColor>



</>
  );
}