import * as React from 'react';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';
import './ClaimerComp.css';

const  ClaimerBlockMain = props => {

  const PaperColor = styled(Paper)({
    backgroundColor:'#6aceeb37'

  });

  const onClick = (rmNb) => {
      alert("onClick BlockMain ");
    };


return (
<>







<PaperColor  sx={{marginBottom:"1%"}} elevation={4}  onClick={() => props.onClickProps(props.id)} 
className={'noselect pointer'} >
<Grid container direction="column"
  justifyContent="center"
  alignItems="center" >

    <Grid item>
        <Box  display="flex"  className={'claimer-title'} justifyContent="center" alignItems="center" marginTop="15px" >
                          
                          {props.params.title}  

        </Box>
    </Grid>
    <Grid item>
        <Box  display="flex"   justifyContent="center" alignItems="center" marginTop="15px" marginBottom="10%" >
                      
                          <img src={props.params.image} class={'claimer-imgmainblock'  } ></img>
        </Box>
    </Grid>

</Grid>
</PaperColor>



</>
  );
}


export default ClaimerBlockMain;