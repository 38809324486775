import * as React from 'react';
import { styled } from '@mui/material/styles';
import { blue ,yellow} from '@mui/material/colors';
import{Box, Paper} from '@mui/material';
import BlockMain from './RoadMapBlockMain';
import Block from './RoadMapBlock';


export default function titi(props) {
  
  const onClick = (rmNb) => {
    alert("onClick AllBlock ");
  };
  
  
  const displayBlocks=()=>{
    if (props.isExpanded) {
    return (
    props.roadmap.blocks.map( (item,i) => {

        return(           <Block  roadmap={props.roadmap.blocks[i]} onClickProps={props.onClickProps}  id={props.id}  ></Block> )

    } ))
  }
  }


  
  
  
  return (
<>


<BlockMain  roadmap={props.roadmap}  onClickProps={props.onClickProps}  id={props.id}  ></BlockMain>
{displayBlocks()}



</>
  );
}

