import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';
import './CCComp.css';
import { Alert, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ViewColumnOutlined } from "@mui/icons-material";
import {NAMES} from '../../section/4-NewWorldOrderSection/NewWorldOrderSection.js';
import ClaimerCheckButton from "./ClaimerCheckButton";

const  ClaimerCheckBlock = props => {


  const [values, setValues] = useState({tokenId: '',});
  const [collection, setCollection] = useState(NAMES.MORIES);
  const [helperTxt, setHelperTxt] = useState('');

  const inputProps = {
    style : {textAlign: 'center',
             color:'white',
             fontFamily:'Eras Bold',
             fontSize:35
                },
  };


  const inputLabelProps = {
    style : {textAlign: 'center',
             color:'white',
             fontFamily:'Eras Bold',
             fontSize:20
                },
    shrink : true,
         
  };
  

  const onImgClick = (e) => {
    e.stopPropagation();  
    if (collection !=e.target.name) {
    setCollection(e.target.name);
    setValues({tokenId: '',});
    }
    };

    
    const handleChange = e =>  {
      setHelperTxt('');
      if (collection==NAMES.MORIES) {
          if ((e.target.value>=0) && (e.target.value<10000)) { 
            setValues({  ...values,[e.target.name]: e.target.value,});
          }else {
            setHelperTxt(NAMES.MORIEHT);
          }
      } else {
        if ((e.target.value>=0) && (e.target.value<=125)) { 
          if ((values.tokenId ==0) && (e.target.value ==0)) {
            setHelperTxt(NAMES.SAMOSHT);
          } else {
            setValues({  ...values,[e.target.name]: e.target.value,});
          }
        }else {
          setHelperTxt(NAMES.SAMOSHT);
        }
      }
    };

    const onCheckBtnClick = (e) => {
      e.stopPropagation();  
      props.onCheckToken(collection,values.tokenId);
      };


    //onClick={() => props.onClickProps(props.id)} 

return (
<>




<Paper  sx={{marginBottom:"1%",backgroundColor:'#6aceeb10'}} elevation={4}    className={'noselect'}   >


<Grid container direction="column"
  justifyContent="flex-start"
  alignItems="center"  > 


    <Grid container justifyContent="center" direction="row"  alignItems="center" > 
      <Grid item xs={2}  >
            <Box display="flex"   justifyContent="center" alignItems="center" marginTop="15px">
                          <img onClick={(e)=> {onImgClick(e); }} name={NAMES.MORIES}   src={props.params.image1} 
                          class={collection==NAMES.MORIES?'cccomp-imgblockselected pointer':'cccomp-imgblock pointer'} ></img>
            </Box>    
        </Grid>
        <Grid item xs={2} >
        <Box display="flex"   justifyContent="center" alignItems="center" marginTop="15px">
                             <img  onClick={(e)=> {onImgClick(e); }} name={NAMES.SAMOS}   src={props.params.image2} 
                              class={collection==NAMES.SAMOS?'cccomp-imgblockselected pointer':'cccomp-imgblock pointer'}></img>
         </Box>                    
       </Grid>

    </Grid>

   


    <Grid item>
        <Box  display="flex"  className={'rmcomp-text'} justifyContent="center" alignItems="center" textAlign="justify"
            marginTop="25px" marginBottom="15px" >
         <TextField  
                        inputProps={inputProps}
                        InputLabelProps={inputLabelProps} // font size of input label
                        sx={{width:'250px',marginRight:'15px' }} id="outlined-basic"
                        label={collection==NAMES.MORIES?NAMES.MORIEFULL:NAMES.SAMOSFULL}  
                        value={values.tokenId}
                        onChange={handleChange}
                        name="tokenId"
                       variant="outlined" 
                       onClick={(e)=> {e.stopPropagation(); }} 
                       autoComplete='off'
                       type="number"
                       helperText={helperTxt}
                    
         />
        </Box>
    </Grid>

    
    <Grid item>
        <Box    display="flex"   justifyContent="center" alignItems="center"  marginBottom="30px"  >
                          
                         <ClaimerCheckButton variant="contained" disableElevation={true} 
                                onClick={(e)=> {onCheckBtnClick(e); }}  status={props.status}  >Check</ClaimerCheckButton>

        </Box>
    </Grid>



</Grid>



</Paper>


</>
  );
}
export default ClaimerCheckBlock;