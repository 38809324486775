import React,{useEffect, useState }  from 'react';
import './styles/App.css';
import Home from './pages/1-HomePage/Home';
import Roadmap from './pages/2-RoadmapPage/Roadmap';
import Cryptomories from './pages/2-RoadmapPage/Cryptomories';
import NewWorldOrder from './pages/4-NewWorldOrderPage/NewWorldOrder';
import MyAdmin from './pages/MyAdminPage/MyAdmin';
import Membership from './pages/3-MembershipPage/Membership';
import MyFooter from './pages/MyFooter/MyFooter';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/LoginPage/Login';
import theme from './styles/theme.js';
import { ThemeProvider } from '@emotion/react';
import {navbar} from './App-Data';




function App() {
  
  const [token, setToken] = useState(false);
  const [alone, setAlone] = useState();

{/*
  if(!token) {
    return <Login setToken={setToken} />
  }
*/}




  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Switch>
         <Route exact path='/home'  component={Home}  />
         <Route exact path='/iwwonworld'  component={Roadmap}  />
         <Route exact path='/cryptomories'  component={Cryptomories}  />
         <Route exact path='/newworldorder'  component={NewWorldOrder}  />
         <Route exact path='/admin'  component={MyAdmin}  />

{/*}

        <Route exact path='/Provenance' component={Provenance} />


        <Route exact path='/sign-up' component={SignUp} />
*/}
        <Route render={() => <Redirect to="/home" />} />  

      </Switch>
{/*  <MyFooter />  */}
    </Router>
    </ThemeProvider>
  );
}

export default App;
