import React from 'react';
import { useEffect, useState } from "react";
import Footer from '../../components/Footer/Footer';
import NewWorldOrderSection from '../../section/4-NewWorldOrderSection/NewWorldOrderSection';
import {newworldorder} from './NewWorldOrder-Data';
import Login from '../LoginPage/Login';



const  Home = (props) => {
  const [saleStatus, setSaleStatus] = useState("");
  const [persoStatus, setPersoStatus] = useState(false);
  const [token, setToken] = useState(false);

  /*
  if(!token) {
    return <Login setToken={setToken} />
  }
*/
  
  return (
    <>
      <NewWorldOrderSection {...newworldorder} />
     <Footer></Footer>

    </>
  );
}

export default Home;
