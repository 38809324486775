import React, { useState, useEffect } from "react"
import { FaTrash } from "react-icons/fa"
//import styles from "./TodoItem.module.css"
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {purple} from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { green , yellow} from '@mui/material/colors';

import {getCurrentWalletConnected,ReadBC, WriteBC } from "../../utils/interact.js";


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';



const yellowPaper = yellow[500]; 


const WhiteListManager = props => {
  
    //State Variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [inputText, setInputText] = useState({title: '', });
    const [editing, setEditing] = useState(false)

    //Other const
    const {contractAddress,abiFileName} =  window['runConfig'];
    const contractABI = require('../../abi/' + abiFileName).abi;

    const importedWiteList= window['wl'];
    const [whitelist,SetWhiteList] = useState([]);


    
    //Load at firts
    useEffect(async () => {
      const {address, status} = await getCurrentWalletConnected();
        setWallet(address)
        setStatus(status);
        addWalletListener(); 
    });     

    //Classical contract function

    function addWalletListener() {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
          if (accounts.length > 0) {
            setWallet(accounts[0]);
            setStatus("👆🏽 Account changed.");
      } else {
            setWallet("");
            setStatus("🦊 Connect to Metamask using the top right button.");
          }
        });
      } else {
        setStatus(
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        );
      }
    }

    const ReadFromBC = async (fn,id,args) => {
      var retVal = await ReadBC(contractABI,contractAddress,fn,args);
      return retVal;
   }

  
    const WriteToBC = async (fn,id,args) => {
      if (walletAddress.length > 0) {
        const { success,status } = await WriteBC(contractABI,contractAddress,fn,0,args);
        if (! success) {alert(status);} 
      } else {
        
        const walletResponse = await getCurrentWalletConnected();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
      }
    }  
    
    
    //Input Form Listener
      const onChange = e => {
         console.log("input =" + inputText.title) ;
       console.log([e.target.name]) ;
        setInputText({
          ...inputText,
          [e.target.name]: e.target.value,
        })

      }






      //Button listener
      const addSingleAdress = async (e) => {
        const retVal = await WriteToBC('addAddressToWhitelist',1,[inputText.title]);
      }

      const importAdressFileToBC = async (e) => {
       
       // alert("wl " + importedWiteList);
        var j=0;
        var step=100;        
        const _whitelist =[];
        for (let i = 0; i < importedWiteList.length; i++) {
          _whitelist.push(importedWiteList[i])
        } 
        
        let ownerList = _whitelist.map(a => a.addr);
        let tokenList = _whitelist.map(a => a.tokenToClaim);
        

        const retVal = await WriteToBC('addAddressesToWhitelist',1,[ownerList,tokenList]);
      }

 
 
      const getAndShowAdresses = async (e) => {
        alert("getfdf");
        const retVal = await ReadFromBC('getWhiteListedAdrrs',1,[]);
        SetWhiteList([]);
        alert("retval "+ retVal);
        SetWhiteList(retVal.slice());
      }



  useEffect(() => {
    return () => {
      console.log("Cleaning up...")
    }
  }, [])


  return (
    
    <Grid container justifyContent="center" direction="column"  alignItems="center" paddingBottom="50px">
        <Accordion>
          <AccordionSummary
                 expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"                  >
                      <Typography variant="h5" component="h5" paddingBottom="10px" >WHITELIST MANAGEMENT</Typography>
          </AccordionSummary>
          
        <AccordionDetails> 
      <Paper    sx={{ p: '2px 4px', 
                    alignItems: 'center', direction: 'column',width: 800,padding:'15px', backgroundColor:yellowPaper }}>

          
         
          <Paper 
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' ,  marginLeft :'auto', marginRight:'auto',marginBottom:'10px',p:'10px'}}  >


                 <TextField  
                            InputLabelProps={{ shrink: true,}}
                            sx={{width:'550px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                            label={"ADD SINGLE ADRESS"}  
                            value={inputText.title}
                            name="title"
                            onChange={onChange}
                            />  
                 
         
              <Button  sx={{width:'100px',marginRight:'15px' }}    
                    variant="contained"  
                    onClick={addSingleAdress} > ADD</Button>
              <Button  sx={{width:'100px',marginRight:'15px' }}    
                    variant="contained"  
                    onClick={getAndShowAdresses} >GET</Button>

              <Button  sx={{width:'100px',marginRight:'15px' }}   
                    variant="contained"  
                    onClick={importAdressFileToBC} >ADD BY IMPORT</Button>

          </Paper>
     
          <Grid container justifyContent="left" direction="column"  alignItems="left" paddingBottom="50px" >
              <Paper>
                { whitelist.map((item,i)=>{
                  return <Grid item  key={i} sx={{width:'700px'}} >  {"Adress " + i +": " + item}</Grid>
                })}
              </Paper> 
            </Grid>

        </Paper>
        </AccordionDetails>
            </Accordion>
    </Grid>

  )
}

export default WhiteListManager