import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';

import { Alert, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ViewColumnOutlined } from "@mui/icons-material";
import {NAMES} from '../../section/4-NewWorldOrderSection/NewWorldOrderSection.js';
import ClaimButton from "./ClaimButton";
import ClaimerCard from './ClaimerCards';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';

const  ClaimerCheckBlock = props => {

/*
  const NAMES = {
    MORIE: "Mories",
    SAMOS: 'Samos',
    MORIEFULL:'CryptoMories',
    SAMOSFULL:'Los Samos',
    MORIEHT:'Token out of bound (0 to 9999)',
    SAMOSHT:'Token out of bound (1 to 125)',

  }
*/
  const [values, setValues] = useState({tokenId: '',});
  const [collection, setCollection] = useState(NAMES.MORIES);
  const [helperTxt, setHelperTxt] = useState('');

  const nbOfItemStyle = {
    color:'white',
    fontFamily:'Eras Bold',
    fontSize:20,
    mr:'10px'
 };


const displayNbOfCardTxt = ()=>{
    if (props.ownTokens.length==0) { return "No cards - No claim"}
    return props.ownTokens.length + " cards"
}

    
  const onCardClicked = (e) => {
      props.onCardClicked();
    };
  
    const onRefreshClicked =  async (e) => {
      /*
      let i=0;
      for(i=0;i<props.ownTokens.length;i++){
        props.ownTokens[i].isSelected=false;
      }
      */
      await props.refresh(props.collectId);
      

    };


      //onClick={() => props.onClickProps(props.id)} 

return (
<>




<Paper  sx={{backgroundColor:'#6aceeb10'}} elevation={4}    className={'noselect'}   >



<Grid container justifyContent='center' alignItems='center' alignContent="center"
 pb='15px' pt='5px'pl='10px' pr='10px' > 




    <Grid container direction='row' alignContent='center' alignItems='center' mb='10px'>
      <Grid item xs={(props.progress < 100)? 4:6 } >
          
           <IconButton  onClick={(e) => onRefreshClicked(e)}  ><RefreshIcon sx={{fontSize:40}}  color='primary'  /> </IconButton>
      </Grid>
      {(props.progress<100) &&  
      <Grid item xs={4}>
      
      <Box sx={{ mt:'0px',color:'white', }} >
           Loading...
        </Box>
        <Box sx={{ mt:'5px' }}>
           <LinearProgress variant="determinate" value={props.progress} />
        </Box>
      </Grid>
      }
      <Grid item  xs={(props.progress < 100)? 4:6 } >
            <Box display='flex' justifyContent='flex-end'  alignContent='center' alignItems='center' 
                  sx={nbOfItemStyle}>

                    {displayNbOfCardTxt()}
            </Box>
      </Grid>

      </Grid>
      <Grid container  rowSpacing={2} columnSpacing={1}  justifyContent="center" xs={12}
          alignItems="center"  >
      {props.ownTokens.map( (item,i) => {

          return( <Grid item>
                <ClaimerCard collectId={props.collectId}  item={item}  onCardClicked={(e)=>onCardClicked(e)} callSnackBar={props.callSnackBar}   />   
                </Grid>    )

           }
       ) }
  </Grid>



   



</Grid>



</Paper>


</>
  );
}
export default ClaimerCheckBlock;