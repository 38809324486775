import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';


import MetaMaskStatus from "../../utils/interact.js";
import { blueGrey } from '@mui/material/colors';






const btnDisplay =(props)=>{
  return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'25px' }}  
  variant="contained" onClick={props.handleClose}>OK</Button>
}






export default function LoaderModal(props) {
  return (
    <Dialog 
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ overflow:'hidden'}}
        PaperProps={{
          style: {
            backgroundColor: '#fff', 
            maskBorderWidth: '5px'
          },
        }}
        disableScrollLock={true}
      >
 
  <DialogContent sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'0px' ,
        marginTop:'0px',  minWidth:'300px',
        maxWidth:'350px',minHeight:'200px' }} >
        <Grid container  alignItems="center" display="flex" alignContent="space-around" justifyContent="space-around" direction="column"  >

        <Grid item >
        <Box sx={{ width: '200px',mt:'20px' }}>
            Loading Morie...
        </Box>    
          </Grid>  
      <Grid item >
        <Box sx={{ width: '200px',mt:'20px' }}>
            <LinearProgress variant="determinate" value={props.progress1} />
        </Box>
      </Grid> 
      <Grid item >
        <Box sx={{ width: '200px',mt:'40px' }}>
            Loading Samos...
        </Box>    
          </Grid>  
      <Grid item >
        <Box sx={{ width: '200px',mt:'20px' }}>
            <LinearProgress variant="determinate" value={props.progress2} />
        </Box>
      </Grid>   

      <Grid item>
        <Box   textAlign='center'  sx={{fontSize:'16px'}} >{props.corpse}</Box>
      </Grid>  

   </Grid>    

</DialogContent>

</Dialog>



  );
}

