import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';

import { Alert, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ViewColumnOutlined } from "@mui/icons-material";
import {NAMES} from '../../section/4-NewWorldOrderSection/NewWorldOrderSection.js';
import {SNACKBAR} from '../../section/4-NewWorldOrderSection/NewWorldOrderSection.js';
import{getMorieImgNb} from "../../utils/myLib.js";


const  ClaimerCheckBlock = props => {

  const [item, setItem] = useState('');
  const [imageURL, setImageURL] = useState();

  useEffect(() => {
    setItem(props.item);
    if (props.collectId==0) {
    setImageURL(NAMES.MORIEIMGURL + getMorieImgNb(props.item.tokenId) +".jpg");
    } else {
      setImageURL(NAMES.SAMOSIMGURL + props.item.tokenId +".jpg");
    }

  }, [item]);;
  

  const [values, setValues] = useState({tokenId: '',});
  const [collection, setCollection] = useState(NAMES.MORIES);
  

  const inputProps = {
    style : {textAlign: 'center',
             color:'white',
             fontFamily:'Eras Bold',
             fontSize:35
                },
  };


  const tokenNbStyle = {
      textAlign: 'center',
      color:'white',
      fontFamily:'Eras Bold',
      fontSize:10

   };

  const cardStyle = {
    backgroundColor:'#6aceeb10',
    width:'100px',
    height:'120px',
    
  };
  
  const cardStyleSelected = {
    backgroundColor:'#6EEB6A',
    width:'100px',
    height:'120px',
  };
  
  const cardStyleNoClaim = {
    backgroundColor:'#515354',
    width:'100px',
    height:'120px',
    
  };


  const onCardClicked = (e) => {
   
    if (item.toClaim>0) {

      let updatedValue = {};
    updatedValue = {isSelected :!props.item.isSelected};
    setItem(item => ({
         ...item,
         ...updatedValue
       }));
      props.item.isSelected=!props.item.isSelected;
      props.onCardClicked();
      }else {
        props.callSnackBar("Token already claimed",SNACKBAR.INFO);
      }
    };
  
const getCardStyle =  () =>{
  if (item.toClaim == 0) return cardStyleNoClaim;
  if (item.isSelected) return   cardStyleSelected;
  return cardStyle;


}


      //onClick={() => props.onClickProps(props.id)} 

return (
<>




<Paper  sx={getCardStyle} elevation={7}    
            className={'noselect pointer'}  
            onClick={e=>onCardClicked(e)}  >


<Grid container direction="column"
  justifyContent="flex-start"
  alignItems="center"  > 


    <Grid item>
      <Box mt='5px' >
          <img width='90px' height='90px' src={imageURL} class={(item.toClaim==0)?'claimer-imgcardnoclaim':'claimer-imgcard'}  />
        </Box>
      </Grid>


      <Grid item>
      <Box sx={tokenNbStyle}> {"# "+ item.tokenId} </Box>
      </Grid>

    
    

         



</Grid>



</Paper>


</>
  );
}
export default ClaimerCheckBlock;