import { useEffect, useState } from "react";
import './Roadmap.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RoadMapAll from '../../components/Roadmap/RoadMapAll';
import Footer from '../../components/Footer/Footer';
import {  NavHashLink } from 'react-router-hash-link';

import BackgroundImage from "../../images/Fond1500x2000.png";
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';


function HomeSection({
sectionId,
title,
roadmaps,
firstExpanded,
}) {


  useEffect(async () => {
        if ((firstExpanded) && (firstWatch)){
           setActiveRM(0);
           setFirstWatch(false); 
          }

  });
  


const divStyle = {
  paperContainer: {
      backgroundImage :  'url('+ BackgroundImage+')',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition : 'center',
  }
}; 


const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});

/* #region active block */

const transitionDuration = 100;

const [activeRM, setActiveRM] = useState(-1);
const [firstWatch, setFirstWatch] = useState(true);

const onChange = rmNb => {
  setActiveRM(-1);
  if (rmNb !=activeRM) {
       setTimeout(() => {
      setActiveRM(rmNb);
     }, transitionDuration);
  };
}

/* #endregion */


  const fabStyle = {
    position: 'absolute',
    top: 16,
    right: 16,
    
  };





  return (
    
    <>
    <ThemeProvider theme={theme}  >
    <Container maxWidth="xl" >
 
    <Box style={divStyle.paperContainer}    sx={{ minHeight:'1000px',paddingBottom:"20vh"}} id={sectionId}
                display="flex"  
                justifyContent="center" 
                alignItems="baseline" 
                 >        

      <Grid  container spacing={{ xs: 3, sm: 5, md: 7 }}  >

          {/* Title */}
          <Grid item xs={12} >  
                  <Box  display="flex"  className={'roadmap-home noselect'} 
                    justifyContent="center" 
                    alignItems="center" 
                  >   
                  <NavHashLink smooth to='/home'> 
                     <IconButton  aria-label={'Home'} color={'primary'}  >
                          <HomeIcon className={'svg_icons'}  />
                      </IconButton>      
                      </NavHashLink>
                  </Box>
              
              <Box  display="flex"  className={'roadmap-title relative noselect'} 
                    justifyContent="center" 
                    alignItems="center" 
                      >
                      {title}  
              </Box>
          </Grid>

          {/* Roadmaps */}
          <Grid item xs={12} >
               <Grid container  direction="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  rowSpacing={3}
                                  
                                  > 

              {roadmaps.map( (item,i) => {
                return (
                            <Grid item key={i} className={'roadmap-rmgrid'} >        
                                    <RoadMapAll id={i}   roadmap={roadmaps[i]}   isExpanded={(i==activeRM)} onClickProps={onChange}   ></RoadMapAll>
                            </Grid>)
              } )}
                                    

                  </Grid>
          </Grid>
      
      </Grid>
    
      
        
    
    </Box>
    </Container>
    </ThemeProvider>

    </>
  );
}

export default HomeSection;