
export const home = {
          sectionId:'Home',
          bannerImg:'images/home/Banner.png',
          backgroundImg:'images/deco/Fond1500x800.png',
          menu1Text:'World of iwwon',
          menu2Text :'Cryptomories',
          logoImg :'images/home/logoIwwon.png',
          mainText :   <div>
                    <p>iwwon was born in Paris in 1990 and then raised in Japan, London, and Paris.<br />
                    Torn apart between his dream of becoming a doctor and his passion for art, he eventually dropped 
                    out of medicine school to pursue his vision in art.<br />He worked as a painter for 10 years, 
                    before creating a videogame studio with BigNenet 5 years ago.<br />iwwon is obsessed with society, 
                    philosophy, personality, civilization, and happiness. He has a very strong need to understand how things work. May it be creation,
                    or mental health.<br />He suspects his passion for creating worlds to actually be a tool to understand the one he lives in.<br/><br/></p>
                    <p>He and BigNenet discovered NFTs in 2020 and decided to pause the game they were developing to focus on 
                    learning everything they could about NFTs, including solidity (the language for ETH contracts). <br />On the 20th of July they minted 
            the Los Samos on their own ERC-721 contract. The iwwon project was born. Los Samos sold out in seconds, allowing them to focus on a more 
            ambitious contract for a 10k avatar series. <br />3 Months later, The CryptoMories dropped and sold out in one hour and half.</p>
        <p><br />Being a multidisciplinary artist, iwwon has a need to work on multiple projects and styles at the same time. It is what feeds 
        his imagination and allows him to make links between ideas.<br />The iwwon project is a reflection of his creative mind. What use to be 
        links between ideas are now easter eggs and used as gamification. The projects feeds one another and add value. Life is a cycle. So is the iwwon project.
        <br/><br/></p>
        <p>But the most important aspect of the iwwon project is that it is alive. It is flexible. It has ultimate goals, but focuses on adapting 
            to new elements. Including it's community.<br />Los Samos had a solid 250-person community. Three of them integrated the team. But with the Mories, 
            the community went parabolic and has reached 25 000 people as of today. More importantly, because of the positivity and the will to welcome and help other
            Mories and communities, it became a main aspect of the project. One that we could not have predict.<br />The Mories project changed. The Mories roadmap changed. 
            The whole iwwon project roadmap changed.<br />We adapted the initial plan to serve the existing community and iwwon believers.<br/><br/></p>
        <p>The beauty of the iwwon project is that the project itself is a creation. And the community is a part of the artist. <br />
        Nobody knows exactly what the final roadmap will look like.<br />Not even iwwon. All the protagonists of the iwwon project, the creator, the team, the community, 
        discover all together the beautiful consequences of their artistic and philosophical choices.<br/><br/></p>
        <p>What we know however, is that whatever happens will always be of the highest quality. It will always add something new and different to what has 
            already been done in the NFT world. It will always add value to the project as a whole, but also to each sub project. And it will always remain true to the original values and philosophy of the iwwon project which are kindness, honesty, carefulness, and art.</p>
        <p>the iwwon team.</p>
               </div>       
          };

      

