import React from 'react';
import { useEffect, useState } from "react";
import Footer from '../../components/Footer/Footer';
import RoadmapSection from '../../section/2-RoadmapSection/RoadmapSection';
import {mories} from './Roadmap-Data';



const  Home = (props) => {
  const [saleStatus, setSaleStatus] = useState("");
  const [persoStatus, setPersoStatus] = useState(false);
  
  
  useEffect(() => {
      var {saleStatus} =  window['runConfig']; 
    setSaleStatus(saleStatus);
    }
  , [])
  
  


  
  
  return (
    <>
      <RoadmapSection {...mories} />
     <Footer></Footer>

    </>
  );
}

export default Home;
