import React, { useState, useEffect } from "react"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {purple} from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { green , yellow} from '@mui/material/colors';

import MetamaskStatus,{ getNbOfOwnedToken, getBalanceOf,ReadBC, WriteBC } from "../../utils/interact.js";


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Refresh } from "@mui/icons-material";



const yellowPaper = yellow[500]; 


const MoneyManager = props => {
  
    //State Variables
    const [walletAddress, setWallet] = useState("");
    const [balance, setBalance] = useState("NA");
    const [balanceOwner, setBalanceOwner] = useState("NA");

    const [inputText, setInputText] = useState({title: '', });
    
    const [maxSupply, setMaxSupply] = useState("");
    const [ nbOfOwnToken,setNbOfOwnedToken ] = useState("");

    //Other const
    const {contractAddress,abiFileName} =  window['runConfig'];
    const contractABI = require('../../abi/' + abiFileName).abi;

 
    
    //Load at firts
    useEffect(async () => {
    });     

    //Classical contract function



    const ReadFromBC = async (fn,id,args) => {
      var retVal = await ReadBC(contractABI,contractAddress,fn,args);
      return retVal;
   }

  
    const WriteToBC = async (fn,id,args) => {
      if (props.walletAddress.length > 0) {
        alert(props.walletAddress);
            const { success,status } = await WriteBC(contractABI,contractAddress,fn,0,args);
        if (! success) {alert(status);} 
      } 
    }  
    

      //Button listener
      const addSingleAdress = async (e) => {
        const retVal = await WriteToBC('addAddressToWhitelist',1,[inputText.title]);
      }

      const importAdressFileToBC = async (e) => {
        const retVal = await WriteToBC('addAddressesToWhitelist',1,[]);
      }

 
      const withDraw = async (e) => {
        if (props.status==MetamaskStatus.ACCOUNT_CONNECTED) {
          const retVal = await WriteToBC('withdraw',[]);
         }
      }

      const reserve = async (e) => {
          if (props.status==MetamaskStatus.ACCOUNT_CONNECTED) {
            const retVal = await WriteToBC('reserveToken',[]);
         }
        }
 
      const getBalance = async (e) => {
        
        if (props.status==MetamaskStatus.ACCOUNT_CONNECTED) {
            const retVal = await getBalanceOf(contractABI,contractAddress);
            setBalance(retVal)  ;
            
           const retVal2 = await getBalanceOf(contractABI,props.owner);
           
            setBalanceOwner(parseFloat(retVal2).toFixed(3))  ;
        }
        
      }

      const getTokenAndSupply = async () => {
        if (props.status==MetamaskStatus.ACCOUNT_CONNECTED) {
             const {ownedToken} = await getNbOfOwnedToken(contractABI,contractAddress);
            setNbOfOwnedToken(ownedToken);
            const _maxSupply = await ReadBC(contractABI,contractAddress,'totalSupply',[]) 
            setMaxSupply(_maxSupply); 
        }
        return "";
      }




  useEffect(() => {
    return () => {
      console.log("Cleaning up...")
    }
  }, [])


  return (
    
    <Grid container justifyContent="center" direction="column"  alignItems="center" paddingBottom="50px" >

            <Accordion>
                <AccordionSummary   expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content"
                      id="panel1a-header"                  >
                      <Typography variant="h5" component="h5" paddingBottom="10px" >
                        {"MONET MANAGEMENT  ("+ props.status+")"}
                        </Typography>
                </AccordionSummary>
          
                <AccordionDetails> 
                <Grid container justifyContent="center" direction="row"  alignItems="center"  
                        width="800px" rowSpacing={5}
                        paddingBottom="50px"   >
                      

                      {/* Row Balance and withdraw */}
                      <Grid item xs={2}   id="balanceRefresh-btn">
                         <Button  sx={{width:'100px',marginRight:'15px' }}
                            variant="contained"  endIcon={<Refresh/>}
                            onClick={getBalance} > UPDATE</Button>
                       </Grid>
        
        
                      <Grid item  xs={4} id="balanceItem" >
                          <TextField  sx={{width:'50px'}} 
                              InputLabelProps={{ shrink: true,}}
                              inputProps={{ style: {textAlign: 'center',backgroundColor:"lightGray"} }}
                              sx={{width:'150px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                              label={"Balance contract"}  
                              value={balance + " ETH"}
                              name="title"
                          />  
                      </Grid>



                       <Grid item xs={2}   id="balanceRefresh-btn">
                         <Button  sx={{width:'100px',marginRight:'15px' }}
                            variant="contained" 
                            onClick={withDraw} > WITHDRAW</Button>
                       </Grid>


                       <Grid item  xs={4} id="owner balance" >
                          <TextField  sx={{width:'50px'}} 
                              InputLabelProps={{ shrink: true,}}
                              inputProps={{ style: {textAlign: 'center',backgroundColor:"lightGray"} }}
                              sx={{width:'150px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                              label={"Balance owner"}  
                              value={ balanceOwner + " ETH"}
                              name="title"
                          />  
                      </Grid>

                       {/* Row Balance and withdraw */}
                      

                       {/* Row Reserve and ownership */}
                       <Grid item xs={2}   id="balanceRefresh-btn">
                         <Button  sx={{width:'100px',marginRight:'15px' }}
                            variant="contained"  endIcon={<Refresh/>}
                            onClick={getTokenAndSupply} > UPDATE</Button>
                       </Grid>
        
        
                      <Grid item  xs={4} id="OwnedToken" >
                          <TextField  sx={{width:'50px'}} 
                              InputLabelProps={{ shrink: true,}}
                              inputProps={{ style: {textAlign: 'center',backgroundColor:"lightGray"} }}
                              sx={{width:'150px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                              label={"Owned Token"}  
                              value={nbOfOwnToken}
                              name="title"
                          />  
                      </Grid>



                       <Grid item xs={2}   id="Reserve-btn">
                         <Button  sx={{width:'100px',marginRight:'15px' }}
                            variant="contained" 
                            onClick={reserve} > RESERVE </Button>
                       </Grid>


                       <Grid item  xs={4} id="total supply" >
                          <TextField  sx={{width:'50px'}} 
                              InputLabelProps={{ shrink: true,}}
                              inputProps={{ style: {textAlign: 'center',backgroundColor:"lightGray"} }}
                              sx={{width:'150px',marginRight:'15px' }} id="outlined-basic" variant="outlined" 
                              label={"Max Supply"}  
                              value={ maxSupply}
                              name="title"
                          />  
                      </Grid>


                       {/* Row Balance and withdraw */}



                    </Grid>
  
        </AccordionDetails>
            </Accordion>

    </Grid>

  )
}

export default MoneyManager