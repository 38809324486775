
const roadmaps = [
      {id:0,
        title: 'Cryptomories',
        image :  '/images/roadmap/mories.jpg',
        corpse :<div>I am cryptomories</div>,
        blocks : [
        {   id:0,
            title: '',
            image : '',
            corpse :<div><p></p>
                    </div>
        },
        ]
    } 

]

const alerts = [
    {id:0,
    title: <p>Claim result</p>,
    corpse :<div>
    <p>Token can claim</p>
    </div>},
]



export const newworldorder = {
            sectionId:'New World Order',
            title:<p>New World Order</p>,
            backgroundImg:'images/deco/Fond1500x800.png',
            firstExpanded:false,
            alerts:alerts,
            claimers : [
                {id:0,
                    title: 'Cryptomories Owners',
                    image :  '/images/roadmap/mories.jpg',
                    ratio :1,
                    
                },{id:1,
                    title: 'Los Samos Owners',
                    image :  '/images/roadmap/lossamos.jpg',
                    ratio :5,
                    
                }],
            claimercheck : {id:2,
                title: 'Check if can claim',
                image1 :  '/images/roadmap/mories.jpg',
                image2 :  '/images/roadmap/lossamos.jpg',
                corpse :<div>I am cryptomories</div>,
                
            }
};      

