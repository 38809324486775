import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';

import { Alert, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ViewColumnOutlined } from "@mui/icons-material";
import {NAMES} from '../../section/4-NewWorldOrderSection/NewWorldOrderSection.js';
import ClaimButton from "./ClaimButton";

const  ClaimerCheckBlock = props => {

/*
  const NAMES = {
    MORIE: "Mories",
    SAMOS: 'Samos',
    MORIEFULL:'CryptoMories',
    SAMOSFULL:'Los Samos',
    MORIEHT:'Token out of bound (0 to 9999)',
    SAMOSHT:'Token out of bound (1 to 125)',

  }
*/
  const [values, setValues] = useState({tokenId: '',});
  const [collection, setCollection] = useState(NAMES.MORIES);
  const [helperTxt, setHelperTxt] = useState('');

  const inputProps = {
    style : {textAlign: 'center',
             color:'white',
             fontFamily:'Eras Bold',
             fontSize:35
                },
  };


  const inputLabelProps = {
    style : {textAlign: 'center',
             color:'white',
             fontFamily:'Eras Bold',
             fontSize:20
                },
    shrink : true,
         
  };
  
  const onClaimBtnClick = (e) => {
      props.onClaimBtnClick(props.collectId);
    };
  

    const tokentoClaim =()=>{

    }

      //onClick={() => props.onClickProps(props.id)} 

return (
<>




<Paper  sx={{marginBottom:"1%",backgroundColor:'#6aceeb10'}} elevation={4}    className={'noselect'}   >


<Grid container direction="column"
  justifyContent="flex-start"
  alignItems="center"  > 


      <Grid item marginTop='25px' marginBottom='25px'>

      <ClaimButton
          status={props.status} 
          onClick={(e)=> {onClaimBtnClick(e); }}  
          tokenToClaim={props.tokenToClaim}  
      ></ClaimButton>
      
      </Grid>

{/*}
      <Grid item>
        { props.ownTokens ? props.ownTokens.length : 0}
      </Grid>

      {props.ownTokens.map( (item,i) => {

          return(     <Grid item>{"#"+ item.tokenId + " claimable " + item.toClaim}</Grid>       )

           }
       ) }

          */}

   



</Grid>



</Paper>


</>
  );
}
export default ClaimerCheckBlock;