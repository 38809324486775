import { useEffect, useState } from "react";
import React from 'react';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import { purple, green , yellow} from '@mui/material/colors';
import ContractManager from '../../components/ContractManager/ContractManager';
import { contractManager, mythosMinter } from './myfooter-data';
import detectEthereumProvider from '@metamask/detect-provider';
import {openInNewTab} from '../../utils/myLib';

import MetaMaskStatus, {
  getCurrentWalletConnected,
  ReadBC,
  connectWallet
} from "../../utils/interact.js";


import './MyFooter.css';



const yellowPaper = yellow[500]; 
const colorBg = '#222'; 
const structureImg = "images/footersection/Footer_Structure.png";
const landscapeImg = "images/allsection/palmiers.png";
const  MyFooter  = props =>{
 
  
/* #region Stata Variable" */

  const [displayPerso, setDisplayPerso] = useState(false);

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [curChainId, setChainId] = useState("");

  const [token2Mint, setToken2Mint] = useState("");
  const [ nbOfOwnToken,setNbOfOwnedToken ] = useState("");

  const {contractAddress,abiFileName,CHAIN_ID} =  window['runConfig'];
  
  const contractABI = require('../../abi/' + abiFileName).abi;
  
/* #endregion */

  

useEffect(async () => {

  const provider = await detectEthereumProvider();
  
  if (provider) {
    
      var {CHAIN_ID} =  window['runConfig'];

      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
      if (_chainId != CHAIN_ID) { 
          setStatus(MetaMaskStatus.WRONG_NETWORK);
      } else {
          const walletResponse = await getCurrentWalletConnected();
          setStatus(walletResponse.status);
          setWallet(walletResponse.address);
      }
      addChainListener();
      addWalletListener();

    } else {
      setStatus(MetaMaskStatus.NO_METAMASK);
}

}, [status]);;


function addChainListener() {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId);
        if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
        }       
      });  
  }
}



function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setDisplayPerso(false);
      } else {
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      }
    });
  } 
}




const onButtonClicked = async () =>{
  if (window.ethereum) {
    const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
    setChainId(_chainId);
   if (_chainId != CHAIN_ID) {
        setStatus(MetaMaskStatus.WRONG_NETWORK);
   } else {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    if (status==MetaMaskStatus.ACCOUNT_CONNECTED) {
        const _owner = await ReadBC(contractABI,contractAddress,'owner',[]) 
      //alert("Owner = " + _owner + "\n selected adress "+ walletAddress );
     if  (walletAddress.toLowerCase() ==_owner.toLowerCase()) setDisplayPerso(true);
    }
   }
}else {
  setStatus(MetaMaskStatus.NO_METAMASK);
}
 
}//OnButtonClicked


const onTwitterClick = () => {
  const url="https://twitter.com/SSF_NFT";
  openInNewTab(url);
} 
const onDiscordClick = () => {
  const url="https://discord.com/invite/rmpTC9JQ9k";
  openInNewTab(url);
} 

  
  
  return (
    <>
      {displayPerso ? <ContractManager {...contractManager} /> : null}
      <Box  display="flex"  justifyContent="center" id={'footer'}>
      <Grid container className={'topcontainer'}     justifyContent="center" marginBottom="0px"  >

          <Grid item>
                <Box  className={'relativecontainer'} >

                            {/* Image cadre */}
                            <Box >
                            <img  src={structureImg}></img>
                            </Box>

                            <Box >
                              <button className={'myfooter-twitterBtn'} onClick={a=>onTwitterClick()} ></button>
                            </Box>  
                            <Box >
                              <button className={'myfooter-discordBtn'} onClick={a=>onDiscordClick()} ></button>
                            </Box>  

                </Box>

          </Grid>
      </Grid>
    {/*
     <Box className={'myfooter-landscape'}   display='flex' alignItems="center" justifyContent="center">
                                <img  src={landscapeImg}></img>
      </Box>  
    */}
      </Box> 


    </>
  );
}

export default MyFooter;