

 
  export   const openInNewTab = (url) => {
     console.log("url " + url) ;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  export const getFishImg = (nb,isFocus,isBack)=>{

    let back=(isBack)?"_Back":"";
    let focus=(isFocus)?"_Focus":"_Flou";
      return 'images/fishes/F'+nb+focus+back+".png";
  }


  export const shortWallet = (aWallet)=> {
    if (!aWallet) return;
    return left(aWallet,6)+ "....."+right(aWallet,6);
  }
  
  export function right(str, chr) {
    return str.slice(str.length-chr,str.length);
  }
   
  export function left(str, chr) {
    return str.slice(0, chr - str.length);
  }

  export function mod(n, m) {
    return ((n % m) + m) % m;
  }

export const getMorieImgNb = (tokenId) => {
   return ( (tokenId*1 +620 +1 <10000)?tokenId*1 +620 +1:tokenId*1 +620 +1-10000);
}

