import * as React from 'react';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { blue ,yellow} from '@mui/material/colors';
import{Box, Paper} from '@mui/material';
import BlockMain from './ClaimerBlockMain';
import Block from './ClaimerBlock';
import BlockCardSelect from './ClaimerCardSelectBlock';
import TextField from '@mui/material/TextField';
import { Group } from '@mui/icons-material';

const ClaimerCheckerMain = (props)=> {
  
  const [tokenToClaim, setTokenToClaim] = useState('');

  
const onCardClicked =()=>{
  
  let _tokenToClaim=0 ;
  let i=0;

    for(i=0;i<props.ownTokens.length;i++){
        if (props.ownTokens[i].isSelected) {
          _tokenToClaim+=props.params.ratio;
        }
    }
    setTokenToClaim(_tokenToClaim);
  //  alert("Main cardClicked " + tokenToClaim )  ;
}





  const displayBlocks=()=>{
    if (props.isExpanded) {
    return (
  
         <> 
         <Block  
             
              collectId={props.id}
              params={props.params} 
              onClaimBtnClick = {props.onClaimBtnClick}
              tokenToClaim={tokenToClaim}
              status={props.status} 
               >
         </Block> 
         <BlockCardSelect  
                  collectId={props.id}
                   params={props.params} 
                   onClickProps={props.onClickProps} 
                   onClaimBtnClick = {props.onClaimBtnClick}
                   onCardClicked={onCardClicked} 
                   ownTokens={props.ownTokens}
                   status={props.status} 
                   callSnackBar={props.callSnackBar} 
                   refresh={props.refresh}
                   progress={props.progress} >
          </BlockCardSelect> 
      </>
  )
  }
  }
  
  return (
<>


<BlockMain  params={props.params}  onClickProps={props.onClickProps} id={props.id}   ></BlockMain>
{displayBlocks()}


</>
  );
}

export default ClaimerCheckerMain;