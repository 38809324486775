import { useEffect, useState} from "react";
import * as React from 'react';
import './NewWorldOrder.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';//for snacknar


import { ThemeProvider, createTheme } from '@mui/material/styles';
import ClaimerCheck from '../../components/ClaimerCheck/ClaimerCheckMain';
import Claimer from '../../components/Claimer/ClaimerMain';


import {  NavHashLink } from 'react-router-hash-link';

import BackgroundImage from "../../images/Fond1500x2000.png";
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';

import AlertNoMetaMask from "../../components/MUI/AlertNoMetamask";
import ClaimerCheckPopup from '../../components/ClaimerCheck/ClaimerCheckPopup';
import LoaderModal from "../../components/MUI/LoaderModal";

import detectEthereumProvider from '@metamask/detect-provider';
import{getMorieImgNb} from "../../utils/myLib.js";

import MetaMaskStatus, {
  getCurrentWalletConnected,
  getNbOfOwnedToken, 
  ReadBC,
  WriteBC,
  getMetaFile,
  getTokensFromAddr,
  connectWallet
} from "../../utils/interact.js";
import { FlashOnRounded } from "@mui/icons-material";


const moriesImg = "images/roadmap/mories.jpg";
const samosImg = "images/roadmap/samos.jpg";

export const NAMES = {
  MORIES: "Mories",
  SAMOS: 'Samos',
  MORIEFULL:'CryptoMories',
  SAMOSFULL:'Los Samos',
  MORIEHT:'Token out of bound (0 to 9999)',
  SAMOSHT:'Token out of bound (1 to 125)',
  MORIEIMGURL: 'https://www.iwwon.com/collectionsimg/mories150x150/',
  SAMOSIMGURL: 'https://www.iwwon.com/collectionsimg/samos150x150/',
}

export const SNACKBAR = {
  SUCCES: "success",
  WARNING: 'warning',
  INFO:'info',
  ERROR:'error',
}



function NewWorldOrderSection({
sectionId,
title,
claimers,
alerts,
claimercheck,
firstExpanded,
}) {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });



  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [curChainId, setChainId] = useState("");
  const [moriesTokens, setMorieTokens] = useState([]);
  const [samosTokens, setSamosTokens] = useState([]);

  const {contractAddress,contractCMAddress,contractLSAddress,abiFileName,CHAIN_ID} =  window['runConfig'];
  const contractABI = require('../../abi/' + abiFileName).abi;
  const [isMorieTokenFetch, setIsMorieTokenFetch] = useState(false);
  const [isSamosTokenFetch, setIsSamosTokenFetch] = useState(false);

  useEffect(async () => {
        if ((firstExpanded) && (firstWatch)){
           setActiveRM(0);
           setFirstWatch(false); 
          }
            setOpenSnackBar(false);
 
    const provider = await detectEthereumProvider();
    
    if (provider) {
      
        var {CHAIN_ID} =  window['runConfig'];
  
        const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
        setChainId(_chainId);
        if (_chainId != CHAIN_ID) { 
            setStatus(MetaMaskStatus.WRONG_NETWORK);
        } else {
            const walletResponse = await getCurrentWalletConnected();
            setStatus(walletResponse.status);
            setWallet(walletResponse.address);
           
        }
        addChainListener();
        addWalletListener();
  
      } else {
        setStatus(MetaMaskStatus.NO_METAMASK);
  }
  
  
  }, [status]);;
  


const divStyle = {
  paperContainer: {
      backgroundImage :  'url('+ BackgroundImage+')',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition : 'center',
  }
}; 


const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});


/* #region MetamaskManagement */

const  connectToMetamask =  async () => {
  
  if (window.ethereum) {
      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
     if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
          alertMetamask();
     } else {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);

     }
  }else {
    setStatus(MetaMaskStatus.NO_METAMASK);
    AlertNoMetaMask()

  }
}



function addChainListener() {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId);
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
        if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
        }       
      });  
  }
}



function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
       
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      } else {
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      }
    });
  } 
}





/* #endregion */

/* #region Alert Metamask management   */

const [open, setOpen] = useState(false);

const alertMetamask = () => {
  setOpen(true);
 }

const handleClose = async () => {
  setOpen(false);
};

const signIn = async () => {
  setOpen(false);
  if (status==MetaMaskStatus.ACCOUNT_NOT_CONNECTED) {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    //const ret = await refreshContract();
  }
};

/* #endregion */

/* #region Alert management  */

const [openClaimerCheckPopup, setOpenClaimerCheckPopup] = useState(false);
const [contractAlertTitle, setContractAlertTitle] = useState(false);
const [contractAlertText, setContractAlertText] = useState(false);
const [imgClaimerCheckPopup, setImgClaimerCheckPopup] = useState(moriesImg);
const [waitingImgAlert, setWaitingImgAlert] = useState(true);
const[snackBarMessage,setSnackBarMessage] = useState([]);
const[isSnackBarOpen,setOpenSnackBar]=useState(false);
const[severity,setSeverity]=useState(false);//for snackbar
const[counter,setCounter]=useState(0);

const[loaderOpen,setLoaderOpen]=useState(false);
const[progress1,setProgress1]=useState(100);
const[progress2,setProgress2]=useState(100);

const handleCloseSnackBar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpenSnackBar(false);
};


const openSnackBar = (msg,_severity) => {
  setSeverity(_severity);
  setSnackBarMessage(msg);
  setOpenSnackBar(true);
};


const setUpClaimerCheckPopupTxtAndOpen = (collection,tokenId,claimableToken) => {

  if (claimableToken>0) {
    setContractAlertText(<div><p># {tokenId} </p><p>{claimableToken} NWO to claim </p></div>);
  } else {
    setContractAlertText(<div><p># {tokenId} </p><p> NWO already claimed </p></div>); 
  }
  setOpenClaimerCheckPopup(true);
 }


 const handleCloseContractAlert = async () => {
  setOpenClaimerCheckPopup(false);
};

/* #endregion */


/* #region active block */

const transitionDuration = 200;

const [activeRM, setActiveRM] = useState(-1);
const [firstWatch, setFirstWatch] = useState(true);

//on main block change
const onChange = blockNb => {
 
  if (status!=MetaMaskStatus.ACCOUNT_CONNECTED) {
    alertMetamask();
  }else {
  if ((!isMorieTokenFetch)  && (blockNb==0) ) {
    refreshContract(0);
    setIsMorieTokenFetch(true);
  }
  if ((!isMorieTokenFetch)  && (blockNb==1) ) {
    refreshContract(1);
    setIsSamosTokenFetch(true);
  }
  setActiveRM(-1);
  if (blockNb !=activeRM) {
       setTimeout(() => {
      setActiveRM(blockNb);
     }, transitionDuration);
  };
}
}

/* #endregion */

const refreshContract = async (_collection) => {
 
  let _moriesTokens=[];
  let _samosTokens=[];

  if (status==MetaMaskStatus.ACCOUNT_CONNECTED) {
  
   if (_collection==0) {
        setMorieTokens([]);
        setProgress1(0);
        _moriesTokens=  await refreshOwner(contractCMAddress,'canMorieClaim',_collection);
        setProgress1(100);
    }
    if (_collection==1) {
        setSamosTokens([]);
        setProgress2(0);
        _samosTokens=  await refreshOwner(contractLSAddress,'canLosSamosClaim',_collection);
        setProgress2(100);
    }
    

  }
  
}
const refreshOwner = async (_contractAddress,bc_function,_collection) => {
    let tokenOwns=[];
    let _tokens=[];
    let i=0;
    let nbOfToken=0;
    
    nbOfToken=await ReadBC(contractABI,_contractAddress,'balanceOf',[walletAddress]);
   
    for(i=0;i<nbOfToken;i++) {
        const _tokenId = await ReadBC(contractABI,_contractAddress,'tokenOfOwnerByIndex',[walletAddress,i]);
     
        let item={tokenId :0,toClaim:0,isSelected:false};
        item.tokenId=_tokenId;
        item.toClaim=await ReadBC(contractABI,contractAddress,bc_function,[_tokenId]);
        item.isSelected=false;
        _tokens.push(item);
        if (_collection==0) {
          setProgress1(Math.round((i+1)/nbOfToken*100));
          setMorieTokens(_tokens.slice());
        } 
        if (_collection==1) {
          setProgress2(Math.round((i+1)/nbOfToken*100));
          setSamosTokens(_tokens.slice());
        } 

        setCounter(counter+1);
    }

    /*
    tokenOwns=await getTokensFromAddr(contractABI,_contractAddress,walletAddress);
    for(i=0;i<nbOfToken;i++) {
            let item={tokenId :0,toClaim:0,isSelected:false};
            item.tokenId=tokenOwns[i];
            item.toClaim=await ReadBC(contractABI,contractAddress,bc_function,[tokenOwns[i]]);
            item.isSelected=false;
            _tokens.push(item);
       }
       */
     return _tokens;  
}

const onClaimBtnClick = async (id) => {
     
      let claimTokenArray=[];
      let i=0;
      

      if (id==0) { claimTokenArray=setClaimArray(moriesTokens);
      
      if (claimTokenArray.length>0)  {
         const retVal = await WriteBC(contractABI,contractAddress,'moriesOwnerClaimNTokens',0,[claimTokenArray]);
         if (retVal.success) {
          openSnackBar("Claim request successful. Please, refresh when MetaMask confirms",SNACKBAR.SUCCES);
         }else {
          openSnackBar("Claim request failed" ,SNACKBAR.ERROR);
         }
         
      } else {
        openSnackBar("Please select some cards");
      } 
    }   
    if (id==1) { claimTokenArray=setClaimArray(samosTokens);
      
      if (claimTokenArray.length>0)  {
         const retVal2 = await WriteBC(contractABI,contractAddress,'lossamosOwnerClaimNTokens',0,[claimTokenArray]);
         if (retVal2.success) {
          openSnackBar("Claim request successful. Please, refresh when MetaMask confirms",SNACKBAR.SUCCES);
         }else {
          openSnackBar("Claim request failed" ,SNACKBAR.ERROR);
         }
      } else {
        openSnackBar("Please select some cards");
      } 
    }
}

const setClaimArray = ( ownTokens)=>{
  let i=0;
  let claimTokenArray=[];

  for(i=0;i<ownTokens.length;i++){
    if (ownTokens[i].isSelected) {
        claimTokenArray.push(ownTokens[i].tokenId);
    }    
  }
  return claimTokenArray;
}



const onCheckToken = async (collection,tokenId) => {
 if (tokenId=='') return;
  if (status!=MetaMaskStatus.ACCOUNT_CONNECTED) {
    alertMetamask();
  }else {
       setWaitingImgAlert(true);
      setImgClaimerCheckPopup(null);
      setContractAlertText('');
      setOpenClaimerCheckPopup(true);
      if (collection==NAMES.MORIES) {
          let claimableToken=await ReadBC(contractABI,contractAddress,'canMorieClaim',[tokenId]);
          setUpClaimerCheckPopupTxtAndOpen(collection,tokenId,claimableToken);
          setImgClaimerCheckPopup(NAMES.MORIEIMGURL+(getMorieImgNb(tokenId)) +".jpg");
      } else {
        let claimableToken=await ReadBC(contractABI,contractAddress,'canLosSamosClaim',[tokenId]);
        setUpClaimerCheckPopupTxtAndOpen(collection,tokenId,claimableToken);
        setImgClaimerCheckPopup(NAMES.SAMOSIMGURL+tokenId+".jpg");

      }  
      setWaitingImgAlert(false);
  }
}


  return (
    
    <>
    <ThemeProvider theme={theme}  >
    <Container maxWidth="xl" >
 
    <Box style={divStyle.paperContainer}    sx={{ minHeight:'1000px',paddingBottom:"20vh"}} id={sectionId}
                display="flex"  
                justifyContent="center" 
                alignItems="baseline" 
                 >        

      <Grid  container spacing={{ xs: 3, sm: 5, md: 7 }}  >

          {/* Title */}
          <Grid item xs={12} >  
                  <Box  display="flex"  className={'pkb-home noselect'} 
                    justifyContent="center" 
                    alignItems="center" 
                  >   
                  <NavHashLink smooth to='/home'> 
                     <IconButton  aria-label={'Home'} color={'primary'}  >
                      <HomeIcon className={'svg_icons'}  />
                      </IconButton>      
                      </NavHashLink>
                  </Box>
              
              <Box  display="flex"  className={'pkb-title relative noselect'} 
                    justifyContent="center" 
                    alignItems="center" 
                      >
                      {title}  
              </Box>
          </Grid>

          {/* Main Blocks */}
          <Grid item xs={12} >
               <Grid container  direction="column"
                                  justifyContent="center"
                                  alignItems="center" rowSpacing={2}
                                  > 
                            <Grid item  className={'pkb-rmgrid'} >        
                                    <Claimer      
                                                  key={counter}
                                                  id={claimers[0].id}   
                                                  params={claimers[0]}  
                                                  status={status} 
                                                  isExpanded={(activeRM==claimers[0].id)} 
                                                  ownTokens={moriesTokens}
                                                  onClickProps={onChange}
                                                  onClaimBtnClick={onClaimBtnClick}
                                                  onCheckToken={onCheckToken}
                                                  callSnackBar={openSnackBar}
                                                  refresh={refreshContract}
                                                  progress={progress1}
                                                   >
                                    </Claimer>
                            </Grid>
                            <Grid item  className={'pkb-rmgrid'} >          
                                    <Claimer      
                                                  key={counter +200}
                                                  id={claimers[1].id}   
                                                  params={claimers[1]}  
                                                  status={status} 
                                                  isExpanded={(activeRM==claimers[1].id)} 
                                                  ownTokens={samosTokens}
                                                  onClickProps={onChange}
                                                  onClaimBtnClick={onClaimBtnClick}
                                                  onCheckToken={onCheckToken}
                                                  callSnackBar={openSnackBar}
                                                  refresh={refreshContract}
                                                  progress={progress2}
                                                   >
                                    </Claimer>


                            </Grid>


                            <Grid item  className={'pkb-rmgrid'} >        
                                    <ClaimerCheck id={claimercheck.id}   
                                                  params={claimercheck} 
                                                  status={status}   
                                                  isExpanded={(activeRM==claimercheck.id)} 
                                                  onClickProps={onChange}
                                                  onCheckToken={onCheckToken}  >
                                    </ClaimerCheck>
                            </Grid>
                                    

                  </Grid>
          </Grid>
      
      </Grid>
    

    
    </Box>
    </Container>
    
    <AlertNoMetaMask open={open} handleClose={handleClose} status={status} signIn={signIn}  />
    <ClaimerCheckPopup open={openClaimerCheckPopup} handleClose={handleCloseContractAlert} 
    contentImg={imgClaimerCheckPopup} waitingForImg={waitingImgAlert}
    title={contractAlertTitle} corpse={contractAlertText} />
    
      
    <Snackbar open={isSnackBarOpen} autoHideDuration={8000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity={severity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
     </Snackbar>   
     <LoaderModal  open={loaderOpen}   progress1={progress1}  progress2={progress2}  title="Loading moriesImg..."     ></LoaderModal> 


    </ThemeProvider>

    </>
  );
}

export default NewWorldOrderSection;