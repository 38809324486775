
  export const contractManager = {
    colorBg: 'adminColorBk',
    lightText: false,
    lightTextDesc: true,
    headline: 'CONTRACT',
    sectionId:'contract',
    imgtransition:'',
    maxTokenToMint: 20,
    token2MintDefault:5,
    tokenPrice:0.08
    };
