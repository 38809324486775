import React from 'react';
import { useEffect, useState } from "react";
import Footer from '../../components/Footer/Footer';
import HomeSection from '../../section/1-HomeSection/HomeSection';
import {home} from './Home-Data';



const  Home = (props) => {
  const [saleStatus, setSaleStatus] = useState("");
  const [persoStatus, setPersoStatus] = useState(false);
  
  
  useEffect(() => {
      var {saleStatus} =  window['runConfig']; 
    setSaleStatus(saleStatus);
    }
  , [])
  
  


  
  
  return (
    <>
      <HomeSection {...home} />
      <Footer></Footer>

    </>
  );
}

export default Home;
